/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserVideoV2VideoFrame1ImgInner } from './userVideoV2VideoFrame1ImgInner';


export interface UserVideoV2AudioCover { 
    /**
     * BlurHash payload for the cover image
     */
    blur?: string;
    img?: Array<UserVideoV2VideoFrame1ImgInner>;
}

