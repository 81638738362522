import { SessionToken } from '@core/models';
import { AB_TESTING_COOKIE_NAME, AbTestingGroup, TEST_VERSION, TOKEN_NAME } from '@data/config';

function safeDecodeURIComponent(encodedURIComponent: string) {
  try {
    return decodeURIComponent(encodedURIComponent);
  } catch {
    // probably it is not uri encoded. return as is
    return encodedURIComponent;
  }
}

function getCookieRegExp(name: string) {
  return new RegExp('(?:^' + name + '|;\\s*' + name + ')=(.*?)(?:;|$)', 'g');
}

export function getCookie(name: string) {
  try {
    name = encodeURIComponent(name);
    const regExp = getCookieRegExp(name);
    const result = regExp.exec(document.cookie);
    return result?.[1] ? safeDecodeURIComponent(result[1]) : '';
  } catch {
    return '';
  }
}


export function deleteCookie(name: string) {
  try {
    name = encodeURIComponent(name);
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
  } catch {
    // Unable to delete the cookie, silently fail
  }
}

export function getToken(): SessionToken | undefined {
  const queryParams = new URLSearchParams(window.location.search);
  const isSupport = queryParams.get('is_support') === 'true';
  if (isSupport) {
    deleteCookie(TOKEN_NAME);
    return undefined;
  }

  let token: SessionToken | undefined;
  const TOKEN_COOKIE = getCookie(TOKEN_NAME);

  if (TOKEN_COOKIE) {
    try {
      token = JSON.parse(TOKEN_COOKIE) as SessionToken | undefined;
    } catch {
      token = undefined;
    }
  }

  return token;
}

export function getABTestingGroup(): AbTestingGroup {
  const cookieName = `${AB_TESTING_COOKIE_NAME}_${TEST_VERSION}`;
  let group = getCookie(cookieName);

  if (!group) {
    group = Math.random() < 0.5 ? 'a' : 'b';
    document.cookie = `${cookieName}=${group}; path=/`;
  }

  return group as AbTestingGroup;
}
