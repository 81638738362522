/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserVideoV2Flags { 
    /**
     * Whether the video is deleted
     */
    is_deleted?: boolean;
    /**
     * Whether the video is blocked
     */
    is_blocked?: boolean;
}

