/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserVideoV2VideoFrame1 } from './userVideoV2VideoFrame1';
import { UserVideoV2VideoBitratesInner } from './userVideoV2VideoBitratesInner';
import { UserVideoV2VideoScroll } from './userVideoV2VideoScroll';


export interface UserVideoV2Video { 
    scroll?: UserVideoV2VideoScroll | null;
    /**
     * URL to download the video
     */
    download_url?: string | null;
    frame1?: UserVideoV2VideoFrame1;
    bitrates?: Array<UserVideoV2VideoBitratesInner>;
}

