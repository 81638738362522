/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserVideoV2Likes } from './userVideoV2Likes';
import { UserVideoV2Share } from './userVideoV2Share';
import { UserVideoV2Flags } from './userVideoV2Flags';
import { UserVideoV2Comments } from './userVideoV2Comments';
import { UserVideoV2Nutson } from './userVideoV2Nutson';
import { UserVideoV2Audio } from './userVideoV2Audio';
import { UserVideoV2Video } from './userVideoV2Video';
import { UserVideoV2Actions } from './userVideoV2Actions';
import { UserVideoV2Meta } from './userVideoV2Meta';
import { UserVideoV2Author } from './userVideoV2Author';
import { UserVideoV2Legacy } from './userVideoV2Legacy';
import { UserVideoV2Cover } from './userVideoV2Cover';
import { UserVideoV2Views } from './userVideoV2Views';


export interface UserVideoV2 { 
    /**
     * ID of the video
     */
    id?: string;
    /**
     * Group identifier for similar videos
     */
    similarity_group?: string;
    meta?: UserVideoV2Meta;
    cover?: UserVideoV2Cover;
    video?: UserVideoV2Video;
    author?: UserVideoV2Author;
    audio?: UserVideoV2Audio | null;
    likes?: UserVideoV2Likes | null;
    views?: UserVideoV2Views | null;
    share?: UserVideoV2Share | null;
    comments?: UserVideoV2Comments | null;
    nutson?: UserVideoV2Nutson | null;
    legacy?: UserVideoV2Legacy | null;
    actions?: UserVideoV2Actions;
    flags?: UserVideoV2Flags;
}

