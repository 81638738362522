import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  effect,
  ElementRef,
  input,
  Input,
  signal,
  ViewChild,
} from '@angular/core';

import { RouterFacade } from '@core/facades';
import { isTextEllipsisActive } from '@core/helpers';
import {
  FeedItem,
  isFeedItemOfMediaType,
  isFeedItemOfSystemVideoV1Type,
  isFeedItemOfSystemVideoV2Type,
  isFeedItemOfUserVideoV2Type,
  Media,
} from '@core/models';
import { ButtonComponent, IconComponent } from '@shared/components';
import { ShowMoreTextComponent } from '@shared/components/show-more-text/show-more-text.component';
import { RepeatAnimationDirective } from '@shared/directives';
import { ShortNumberPipe } from '@shared/pipes';
import { ProfileUserObject, SystemVideoV1, SystemVideoV1Button, UserVideoV2 } from '@api/backend';
import { I18NextModule } from 'angular-i18next';
import { AnalyticsEventsEnum } from '@core/models/analytics.model';
import { asyncScheduler } from 'rxjs';
import { isNil } from 'lodash';

@Component({
  selector: 'cheelee-about-media',
  standalone: true,
  imports: [
    CommonModule,
    IconComponent,
    ShortNumberPipe,
    NgOptimizedImage,
    ShowMoreTextComponent,
    RepeatAnimationDirective,
    I18NextModule,
    ButtonComponent,
  ],
  templateUrl: './about-media.component.html',
  styleUrls: ['./about-media.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AboutMediaComponent implements AfterViewInit {
  protected readonly analyticsEvents = AnalyticsEventsEnum;

  public isAdVisible = signal(false);

  public isActive = input<boolean>(false);

  private showButtonAfter = signal<number | undefined>(undefined);

  @Input({ required: true })
  public set item(value: FeedItem) {
    if (isFeedItemOfMediaType(value)) {
      this.media.set(value.object);
    } else if (isFeedItemOfSystemVideoV1Type(value) || isFeedItemOfSystemVideoV2Type(value)) {
      this.ad.set(value.object);
      this.showButtonAfter.set(value.object.button?.delay_sec);
    } else if (isFeedItemOfUserVideoV2Type(value)) {
      this.userVideo.set(value.object);
    }
  }

  public media = signal<Media | undefined>(undefined);

  public userVideo = signal<UserVideoV2 | undefined>(undefined);

  public ad = signal<SystemVideoV1 | undefined>(undefined);


  @ViewChild('audioName')
  private readonly audioName!: ElementRef<HTMLSpanElement>;

  public canPlayRunningText = signal(false);

  constructor(
    private routerFacade: RouterFacade,
  ) {
    effect(() => {
      if (this.isActive() && !isNil(this.showButtonAfter())) {
        asyncScheduler.schedule(() => this.isAdVisible?.set(true), this.showButtonAfter()! * 1000);
      }
    });
  }

  public ngAfterViewInit(): void {
    if (this.audioName) {
      this.canPlayRunningText.set(isTextEllipsisActive(this.audioName.nativeElement));
    }
  }

  public goToUserPage(): void {
    let userId = '';

    if (this.media()) {
      userId = this.media()!.author.user_id;
    } else if (this.ad()) {
      const { profile } = this.ad()!;
      if (profile?.type === 'user' && profile.object) {
        userId = (profile.object as ProfileUserObject).user_id ?? '';
      }
    } else if (this.userVideo()) {
      userId = this.userVideo()!.author?.id ?? '';
    }
    if (userId) {
      this.routerFacade.navigate({ path: `/users/${ userId }` });
    }
  }

  protected onButtonClick(button: SystemVideoV1Button) {
    if (button.url) {
      window.open(button.url, '_blank');
    }
  }

  protected getAudioTrackName(artist?: string, title?: string): string {
    return artist && title ? `${artist} - ${title}` : '';
  }

  protected isDarkColor(value: string | null): boolean {
    if (!value) return false; // Если значение null или пустое

    const hex = value.replace('#', '');

    if (hex.length !== 8) return false; // Убедимся, что длина соответствует формату #RRGGBBAA

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    const a = parseInt(hex.substring(6, 8), 16) / 255; // Альфа-канал в диапазоне от 0 до 1

    // Корректируем яркость с учетом прозрачности
    const adjustedR = r * a + 255 * (1 - a); // За основу берем белый цвет
    const adjustedG = g * a + 255 * (1 - a);
    const adjustedB = b * a + 255 * (1 - a);

    // Вычисляем яркость (перцептивная модель)
    const brightness = (adjustedR * 299 + adjustedG * 587 + adjustedB * 114) / 1000;

    return brightness < 128; // Если яркость меньше 128, считаем цвет темным
  }
}
