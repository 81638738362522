import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef, effect,
  inject,
  OnInit,
  Renderer2,
  ViewContainerRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { asyncScheduler } from 'rxjs';
import { filter, first } from 'rxjs/operators';

import { BreakpointFacade, SessionFacade } from '@core/facades';
import { LogService, OverlayService, PlatformService } from '@core/services';

@Component({
  selector: 'cheelee-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, AfterViewInit {

  public isMobile = inject(BreakpointFacade).isMobile;

  constructor(
    private readonly sessionFacade: SessionFacade,
    private readonly renderer: Renderer2,
    private readonly platformService: PlatformService,
    private readonly overlayService: OverlayService,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly destroyRef: DestroyRef,
    private readonly log: LogService,
  ) {
    effect(() => {
      const isMobile = this.isMobile();
      if (isMobile) {
        this.removeStartScreen();
      }
    });
  }

  public ngOnInit(): void {
    if (this.platformService.isBrowser()) {
      this.subscribeToAppIsReady();
    }
  }

  public ngAfterViewInit(): void {
    this.overlayService.setDialogContainer(this.viewContainerRef);
  }

  private subscribeToAppIsReady(): void {
    const appIsReady$ = this.sessionFacade.sessionToken$
      .pipe(
        filter((token) => !!token?.access_token),
      );

    appIsReady$
      .pipe(
        first(),
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(() => this.removeStartScreen());
  }

  private removeStartScreen(): void {
    asyncScheduler.schedule(() => {
      try {
        const container = this.renderer.selectRootElement('.start-screen', true);
        if (container && this.isMobile()) {
          this.renderer.setStyle(container, 'opacity', 0);
          this.renderer.removeChild(container.parentNode, container);
        }
      } catch {
        this.log.error('Error while removing app container');
      }
    }, 750);
  }
}
