import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { isNil } from 'lodash';

import { mediaFeatureKey, MediaState } from '@store/media/media.reducer';
import { feedAdapter, FeedState, mediaViewsAdapter } from '@store/media/entity-adapters';
import { FeedItemEnum, FeedItemOfMediaType } from '@core/models';

const {
  selectAll: feedMediaSelector,
  selectEntities: feedMediaEntitiesSelector,
  selectIds: feedMediaIdsSelector,
} = feedAdapter.getSelectors();

const { selectAll: mediaViewsSelector, selectIds: mediaViewIdsSelector } = mediaViewsAdapter.getSelectors();

const selectMediaFeature = createFeatureSelector<MediaState>(mediaFeatureKey);

const selectMedia = createSelector(selectMediaFeature, (state) => state.media);

const selectMediaFeedItem = createSelector(selectMedia, (media) => {
  if (!media) return;
  return ({
    type: FeedItemEnum.UserVideoV1,
    object: media,
  } as FeedItemOfMediaType);
});

const selectMediaIsLoading = createSelector(selectMediaFeature, (state) => state.mediaIsLoading);

const selectFeedState = createSelector(selectMediaFeature, (state) => state.feed);

// const selectCachedFeedState = createSelector(selectMediaFeature, (state) => state.cachedMediaFeed);

const selectFeed = createSelector(selectFeedState, feedMediaSelector);

// const selectCachedFeed = createSelector(selectCachedFeedState, cachedFeedMediaSelector);

// const selectFeedFromCache = createSelector(selectCachedFeed, (cachedFeed) => cachedFeed);

// const selectLastOpenedMediaIndex = createSelector(selectCachedFeedState, (cachedFeed) => cachedFeed.lastIndex);

const selectFeedIsLoading = createSelector(selectFeedState, (feed) => feed.isLoading);

const selectFeedEntities = createSelector(selectFeedState, feedMediaEntitiesSelector);

const selectFeedIds = createSelector(selectFeedState, feedMediaIdsSelector) as  MemoizedSelector<object, string[], (s1: FeedState) => string[]>;

const selectFeedLength = createSelector(selectFeedIds, (ids) => ids.length);

const selectFeedNextPageToken = createSelector(selectFeedState, (feed) => feed.nextPageToken);

const selectFeedFirstItem = createSelector(selectFeed, (feed) => feed[0]);

// const selectFeedRecommendationsId = createSelector(selectFeedState, (feed) => feed.recommendation_id);

const selectSnippet = createSelector(selectMediaFeature, (state) => state.snippet);

const selectIsPlaying = createSelector(selectMediaFeature, (state) => state.isPlaying);

const selectIsMuted = createSelector(selectMediaFeature, (state) => state.isMuted);

const selectModalViewIsOpen = createSelector(selectMediaFeature, (state) => state.isModalView);

const selectCurrentMediaIndex = createSelector(selectMediaFeature, (state) => state.currentMediaIndex);

const selectCurrentMediaIsTheFirst = createSelector(selectCurrentMediaIndex, (index) => index === 0);

const selectCurrentMediaIsTheLast = createSelector(
  selectCurrentMediaIndex,
  selectFeedLength,
  selectFeedNextPageToken,
  (index, length, token) => index === length - 1 && token === null,
);

const selectCurrentMediaId = createSelector(selectFeedIds, selectCurrentMediaIndex, (ids, currentMediaIndex) => {
  const id = ids[currentMediaIndex];
  return id ? String(id) : undefined;
});

const selectNextMediaId = createSelector(selectFeedIds, selectCurrentMediaIndex, (ids, currentMediaIndex) => {
  const id = ids[currentMediaIndex + 1];
  return id ? String(id) : undefined;
});

const selectPrevMediaId = createSelector(selectFeedIds, selectCurrentMediaIndex, (ids, currentMediaIndex) => {
  const id = ids[currentMediaIndex - 1];
  return id ? String(id) : undefined;
});

const selectCurrentMedia = createSelector(selectFeedEntities, selectCurrentMediaId, (entities, currentMediaId) =>
  !isNil(currentMediaId) ? entities[currentMediaId] : undefined,
);

const selectNextMedia = createSelector(selectFeedEntities, selectNextMediaId, (entities, nextMediaId) =>
  !isNil(nextMediaId) ? entities[nextMediaId] : undefined,
);

const selectPrevMedia = createSelector(selectFeedEntities, selectPrevMediaId, (entities, prevMediaId) =>
  !isNil(prevMediaId) ? entities[prevMediaId] : undefined,
);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const selectMediaById =
  (mediaId: string) => createSelector(selectFeedEntities, (entities) => entities[mediaId]);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const selectMediaByIndex =
  (index: number) => createSelector(selectFeed, (mediaList) => mediaList[index]);

// const selectSubscriptionIsRequested = createSelector(selectMediaFeature, (state) => state.isSubscriptionRequested);

const selectMediaViewsState = createSelector(selectMediaFeature, (state) => state.mediaViews);

const selectMediaViews = createSelector(selectMediaViewsState, mediaViewsSelector);

const selectMediaViewIds = createSelector(selectMediaViewsState, mediaViewIdsSelector);

const selectMediaViewsCount = createSelector(selectMediaViewIds, (ids) => ids.length);

// const selectMediaLikesState = createSelector(selectMediaFeature, (state) => state.mediaLikes);

// const selectMediaLikes = createSelector(selectMediaLikesState, mediaLikesSelector);

// const selectMediaLikesNextPageToken = createSelector(selectMediaLikesState, (mediaLikes) => mediaLikes.nextPageToken);

// const selectAreMediaLikesLoading = createSelector(selectMediaLikesState, (mediaLikes) => mediaLikes.isLoading);

export const mediaSelectors = {
  selectMedia,
  selectMediaFeedItem,
  selectMediaIsLoading,
  selectFeed,
  // selectCachedFeed,
  // selectFeedFromCache,
  // selectLastOpenedMediaIndex,
  selectFeedIsLoading,
  selectFeedIds,
  selectFeedLength,
  selectFeedNextPageToken,
  selectFeedFirstItem,
  // selectFeedRecommendationsId,
  selectSnippet,
  selectIsPlaying,
  selectIsMuted,
  selectModalViewIsOpen,
  selectCurrentMediaIndex,
  selectCurrentMediaIsTheLast,
  selectCurrentMediaIsTheFirst,
  selectCurrentMedia,
  selectCurrentMediaId,
  selectNextMedia,
  selectPrevMedia,
  selectMediaById,
  // selectSubscriptionIsRequested,
  selectMediaViews,
  // selectMediaViewIds,
  selectMediaViewsCount,
  selectMediaByIndex,
  // selectMediaLikesState,
  // selectMediaLikes,
  // selectMediaLikesNextPageToken,
  // selectAreMediaLikesLoading,
};
