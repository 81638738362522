import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import FingerprintJS, { GetResult } from '@fingerprintjs/fingerprintjs';

import { from, map, Observable, of } from 'rxjs';

interface Visitor {
  visitorId: string;
}

const EMPTY_VISITOR_DATA: Visitor = { visitorId: '' };

@Injectable({
  providedIn: 'root',
})
export class FingerprintService {
  private readonly WEB_PREFIX = 'web';

  private timestamp = Date.now().toString();

  private fpPromise = FingerprintJS
    .load()
    .then(fp => fp.get());

  constructor(
    @Inject(PLATFORM_ID)
    private readonly platformId: Object,
  ) {
  }

  public getFingerPrint(): Observable<GetResult> {
    return from(this.fpPromise);
  }

  public getVisitorData(): Observable<Visitor> {
    return isPlatformBrowser(this.platformId) ? this.getDataForBrowser() : this.getDataForSSR();
  }

  private getDataForBrowser(): Observable<Visitor> {
    const queryParams = new URLSearchParams(window.location.search);
    const isSupport = queryParams.get('is_support') === 'true';
    return isSupport
      ? of(this.setPrefix({ ...EMPTY_VISITOR_DATA, visitorId: this.timestamp }))
      : from(this.getFingerPrint())
        .pipe(
          map((visitor) => this.setPrefix(visitor)),
        );
  }

  private setPrefix(visitor: Visitor): Visitor {
    return ({ visitorId: `${this.WEB_PREFIX}_${visitor.visitorId}` });
  }

  private getDataForSSR(): Observable<Visitor> {
    return of<Visitor>(EMPTY_VISITOR_DATA);
  }
}
