import {
  Media,
  SystemVideoV1 as SystemVideoV1Api,
  UserVideoV2 as UserVideoV2Api,
  ViewSource as source,
} from '@api/backend';

export const ViewSource = source;

export type ViewSourceType = (typeof ViewSource)[keyof typeof ViewSource];

export enum FeedItemEnum {
  SystemAppRatingV1 = 'system-app-rating-v1',
  SystemChooseRegionV1 = 'system-choose-region-v1',
  SystemCommonV1 = 'system-common-v1',
  SystemMarketingAgreementV1 = 'system-marketing-agreement-v1',
  SystemRatingV1 = 'system-rating-v1',
  SystemVideoV1 = 'system-video-v1',
  SystemVideoV2 = 'system-video-v2',
  UserVideoV1 = 'user-video-v1',
  UserVideoV2 = 'user-video-v2',
}

export type FeedItemOfMediaType = { type: FeedItemEnum.UserVideoV1, object: Media };

export type FeedItemOfSystemVideoV1Type = { type: FeedItemEnum.SystemVideoV1, object: SystemVideoV1Api };

export type FeedItemOfSystemVideoV2Type = { type: FeedItemEnum.SystemVideoV2, object: SystemVideoV1Api };

export type FeedItemOfUserVideoV2Type = { type: FeedItemEnum.UserVideoV2, object: UserVideoV2Api };

export const permittedFeedTypesSet = new Set([
  <string>FeedItemEnum.UserVideoV1,
  <string>FeedItemEnum.UserVideoV2,
  <string>FeedItemEnum.SystemVideoV1,
  <string>FeedItemEnum.SystemVideoV2,
]);

export type FeedItem =
  | FeedItemOfMediaType
  | FeedItemOfSystemVideoV1Type
  | FeedItemOfSystemVideoV2Type
  | FeedItemOfUserVideoV2Type;
  // | { type: string; object?: unknown };

export type FeedPage = {
  next_page_token?: string,
  items?: Array<FeedItem>,
};

function isUserVideoV2(obj: any): obj is UserVideoV2Api {
  return (
    typeof obj === 'object' &&
    ('id' in obj || 'similarity_group' in obj || 'meta' in obj) &&
    typeof obj.actions === 'object' &&
    typeof obj.flags === 'object'
  );
}

function isMedia(obj: any): obj is Media {
  return (
    typeof obj === 'object' &&
    typeof obj.media_id === 'string' &&
    'author' in obj &&
    typeof obj.created_at === 'number' &&
    'counters' in obj &&
    'moderation_status' in obj &&
    ('media_url' in obj || 'thumbnail_url' in obj || 'media_urls' in obj)
  );
}

export function toFeedItem(input: unknown): FeedItem | null {
  if (isMedia(input)) {
    return {
      type: FeedItemEnum.UserVideoV1,
      object: input,
    };
  }

  if (isUserVideoV2(input)) {
    return {
      type: FeedItemEnum.UserVideoV2,
      object: input,
    };
  }

  // Если объект не подходит ни под один из типов
  return null;
}


export const isFeedItemOfMediaType = (feedItem: FeedItem): feedItem is FeedItemOfMediaType => {
  return feedItem.type === FeedItemEnum.UserVideoV1;
};

export const isFeedItemOfSystemVideoV1Type = (feedItem: FeedItem): feedItem is FeedItemOfSystemVideoV1Type => {
  return feedItem.type === FeedItemEnum.SystemVideoV1;
};

export const isFeedItemOfSystemVideoV2Type = (feedItem: FeedItem): feedItem is FeedItemOfSystemVideoV2Type => {
  return feedItem.type === FeedItemEnum.SystemVideoV2;
};

export const getSystemVideoObject = (feedItem: FeedItem): SystemVideoV1Api | null => {
  if (isFeedItemOfSystemVideoV1Type(feedItem) || isFeedItemOfSystemVideoV2Type(feedItem)) {
    return feedItem.object;
  }
  return null;
};

export const isFeedItemOfUserVideoV2Type = (feedItem: FeedItem): feedItem is FeedItemOfUserVideoV2Type => {
  return feedItem.type === FeedItemEnum.UserVideoV2;
};
