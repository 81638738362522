import { DestroyRef, inject, Injectable, OnDestroy, Renderer2 } from '@angular/core';
import { SwiperContainer } from 'swiper/element';
import Swiper from 'swiper';

type SwiperEvent = CustomEvent<[ swiper: Swiper ]>;


@Injectable()
export class MediaLoaderService implements OnDestroy {

  private destroyRef = inject(DestroyRef);

  private currentIndex = 0;

  private swiper?: SwiperContainer;

  private get initialized(): boolean {
    return !!this.swiper;
  }

  private readonly videoControllers = new Map<number, AbortController>(); // Для отмены загрузок постеров

  private readonly visibleVideos = new Map<number, HTMLVideoElement>(); // Хранилище активных видео

  constructor(
    private readonly renderer: Renderer2,
  ) {
  }

  ngOnDestroy(): void {
    this.cancelAll();
  }

  public init(swiper: SwiperContainer) {
    if (this.initialized) {
      return;
    }
    this.swiper = swiper;

    const unlistenslideChangeTransitionEnd = this.renderer.listen(
      swiper,
      'swiperslidechangetransitionend',
      (event: SwiperEvent) => {
        const [ swiperDetails ] = event.detail;
        this.indexChanged(swiperDetails.activeIndex);
      },
    );

    this.destroyRef.onDestroy(() => {
      unlistenslideChangeTransitionEnd();
    });
  }


  public indexChanged(index: number): void {
    this.currentIndex = index;

    const visibleIndexes = Array.from({ length: 5 }, (_, i) => this.currentIndex - 2 + i);
    this.handleVisibleVideos(visibleIndexes);
  }

  private handleVisibleVideos(visibleIndexes: number[]): void {
    // Отменяем загрузку видео и постеров для индексов вне видимости
    for (const [index] of this.visibleVideos) {
      if (!visibleIndexes.includes(index)) {
        this.cancelVideoAndPosterLoading(index);
      }
    }

    // Загружаем видео и постеры для видимых индексов
    visibleIndexes.forEach(index => {
      this.startVideoAndPosterLoading(index);
    });
  }

  /**
   * Начинает загрузку видео и постеров для указанного индекса
   */
  public startVideoAndPosterLoading(index: number): void {
    const videoElement = this.swiper?.swiper.slides[index]?.querySelector('video');


    if (videoElement) {
      // Загружаем постер
      const posterUrl = videoElement.getAttribute('poster');
      if (posterUrl) {
        this.loadPoster(index, posterUrl);
      }

      // Инициализируем загрузку видео
      if (!this.visibleVideos.has(index)) {
        videoElement.load();
        this.visibleVideos.set(index, videoElement);
      }
    }
  }

  /**
   * Отменяет загрузку видео и постера для указанного индекса
   */
  public cancelVideoAndPosterLoading(index: number): void {
    const videoElement = this.visibleVideos.get(index);
    if (videoElement) {
      videoElement.pause();
      videoElement.removeAttribute('src');
      videoElement.load(); // Сброс элемента
      this.visibleVideos.delete(index);
    }

    const controller = this.videoControllers.get(index);
    if (controller) {
      controller.abort();
      this.videoControllers.delete(index);
    }
  }

  /**
   * Управляет загрузкой постера
   */
  private loadPoster(index: number, url: string): void {
    const controller = new AbortController();
    this.videoControllers.set(index, controller);

    const img = new Image();
    img.src = url;

    const controllerSignal = controller.signal;

    // Завершаем загрузку при успехе
    img.onload = () => {
      this.videoControllers.delete(index);
    };

    // Завершаем загрузку при ошибке
    img.onerror = () => {
      // eslint-disable-next-line no-console
      console.warn(`Poster loading failed for index ${index}`);
      this.videoControllers.delete(index);
    };

    // Обрабатываем отмену загрузки
    controllerSignal.onabort = () => {
      // eslint-disable-next-line no-console
      console.log(`Poster loading aborted for index ${index}`);
    };
  }

  /**
   * Отменяет все текущие загрузки видео и постеров
   */
  public cancelAll(): void {
    this.videoControllers.forEach(controller => controller.abort());
    this.videoControllers.clear();

    this.visibleVideos.forEach(video => {
      video.pause();
      video.removeAttribute('src');
      video.load();
    });
    this.visibleVideos.clear();
  }
}
