/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserVideoV2VideoFrame1ImgInner { 
    /**
     * URL of the image
     */
    url?: string;
    /**
     * Width of the image in pixels
     */
    w?: number;
    /**
     * Height of the image in pixels
     */
    h?: number;
}

