/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserVideoV2NutsonParentResourceReference { 
    /**
     * Type of the resource
     */
    resource_type?: string;
    /**
     * ID of the resource
     */
    resource_id?: string;
    /**
     * URL of the resource
     */
    resource_url?: string | null;
    /**
     * Title of the resource
     */
    resource_title?: string | null;
}

