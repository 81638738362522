import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { generateHash } from '@core/helpers';
import { FeedItem } from '@core/models';

export const feedAdapter: EntityAdapter<FeedItem> = createEntityAdapter<FeedItem>({
  selectId: (feedItem) => `${ feedItem.type }.${ generateHash(feedItem.object) }`,
});


export interface FeedState extends EntityState<FeedItem> {
  nextPageToken: string | null | undefined;
  isLoading: boolean;
}

export const initialFeedState = feedAdapter.getInitialState({
  nextPageToken: undefined,
  isLoading: false,
});
