/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FeedVideoScroll } from './feedVideoScroll';
import { FeedVideoFrame1 } from './feedVideoFrame1';
import { Bitrate } from './bitrate';


export interface FeedVideo { 
    scroll?: FeedVideoScroll | null;
    download_url?: string | null;
    frame1?: FeedVideoFrame1;
    bitrates?: Array<Bitrate>;
}

