import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Inject, Injector, LOCALE_ID } from '@angular/core';

import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, Observable, take, tap } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { filterNil } from '@core/helpers';
import { GetSessionActionTypes, SessionToken } from '@core/models';
import { FingerprintService } from '@core/services';
import { sessionActions } from '@store/session';

export abstract class Interceptor {
  protected isRefreshingToken = false;

  protected constructor(
    protected store: Store,
    protected actions: Actions,
    protected injector: Injector,
    protected fingerprintService: FingerprintService,
    @Inject(LOCALE_ID) protected locale: string,
  ) {}

  protected getSession(
    action: GetSessionActionTypes,
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (!this.isRefreshingToken) {
      return this.handleTokenRequest(action).pipe(
        take(1),
        tap(() => (this.isRefreshingToken = false)),
        switchMap((token) => this.cloneRequestWithHeaders(request, next, token.access_token)),
      );
    } else {
      return this.getAuthTokenSuccess().pipe(
        switchMap((token) => this.cloneRequestWithHeaders(request, next, token.access_token)),
      );
    }
  }

  protected cloneRequestWithHeaders(
    request: HttpRequest<unknown>,
    next: HttpHandler,
    token: string,
  ): Observable<HttpEvent<unknown>> {
    return this.fingerprintService.getVisitorData().pipe(
      switchMap(({ visitorId }) =>
        next.handle(
          request.clone({
            setHeaders: this.getHeaders(visitorId, token),
          }),
        ),
      ),
    );
  }

  protected getHeaders(
    visitorId: string | null,
    token?: string,
    requestId?: string,
  ): { [p: string]: string | string[] } {
    let headers = {
      'Accept-language': this.locale,
      'X-Marketplace-Request-Id': Interceptor.uuid(),
      // 'User-Agent': navigator.userAgent,
    };

    if (visitorId) {
      headers = Object.assign(headers, {
        'X-Marketplace-Device-Id': visitorId,
      });
    }

    if (requestId) {
      headers = Object.assign(headers, {
        'X-Marketplace-Bot-Request-Id': requestId,
      });
    }

    if (token) {
      headers = Object.assign(headers, {
        Authorization: `Bearer ${token}`,
      });
    }

    return headers;
  }

  private handleTokenRequest(action: GetSessionActionTypes): Observable<SessionToken> {
    this.isRefreshingToken = true;
    this.store.dispatch(
      action === GetSessionActionTypes.NEW
        ? sessionActions.getAuthSession()
        : sessionActions.refreshAuthSession(),
    );
    return this.getAuthTokenSuccess();
  }

  private getAuthTokenSuccess(): Observable<SessionToken> {
    return this.actions.pipe(
      ofType(sessionActions.getAuthTokenSuccess),
      filterNil(),
      map(({ token }) => token),
    );
  }

  private static uuid(): string {
    function s4(): string {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4() + s4() + s4()}`;
  }
}
