/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserVideoV2Legacy { 
    /**
     * Media ID
     */
    media_id?: string;
    /**
     * Recommendation ID
     */
    recommendation_id?: string | null;
    /**
     * Moderation status
     */
    moderation_status?: string;
}

