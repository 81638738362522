import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, signal } from '@angular/core';

import { I18NextModule } from 'angular-i18next';
import { DeviceDetectorService } from 'ngx-device-detector';

import { OverlayService } from '@core/services';
import { ModalsNames } from '@data/const';
import { ButtonComponent, DialogWrapperComponent, IconComponent } from '@shared/components';
import { RippleDirective } from '@shared/directives';
import { bannerPicture, BannerPictureType } from '@core/models';
import { AnalyticsEventsEnum } from '@core/models/analytics.model';
import { AnalyticsDirective } from '@shared/directives/analytics.directive';
import { Store } from '@ngrx/store';
import { sessionSelectors } from '@store/session';

@Component({
  selector: 'cheelee-get-mobile-app-dialog',
  standalone: true,
  imports: [
    CommonModule,
    DialogWrapperComponent,
    NgOptimizedImage,
    IconComponent,
    RippleDirective,
    I18NextModule,
    ButtonComponent,
    AnalyticsDirective,
  ],
  templateUrl: './get-mobile-app-dialog.component.html',
  styleUrls: ['./get-mobile-app-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GetMobileAppDialogComponent {
  public readonly isDesktopDevice = signal(this.deviceDetectorService.isDesktop());

  public readonly isIOS = signal(this.deviceDetectorService.getDeviceInfo().os === 'iOS');

  private abPrefix = inject(Store).selectSignal(sessionSelectors.selectABGroupPostfix);

  public picture = input<BannerPictureType>();

  public pictureData?: BannerPictureType;

  public getRandomPicture = bannerPicture[Math.floor(Math.random() * bannerPicture.length)];

  constructor(
    private readonly overlayService: OverlayService,
    private readonly deviceDetectorService: DeviceDetectorService,
  ) {}

  public close(): void {
    this.overlayService.close(ModalsNames.GetApp);
  }

  onButtonClick() {
    const oneLink = 'https://cheelee.onelink.me/rmPd/s9eqs322';
    window.location.href = `${oneLink}${this.abPrefix() ? `?af_adset=${this.abPrefix()}` : ''}`;
  }

  protected readonly analyticsEvents = AnalyticsEventsEnum;
}
