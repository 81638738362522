/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserVideoV2NutsonParentResourceReference } from './userVideoV2NutsonParentResourceReference';


export interface UserVideoV2Nutson { 
    parent_resource_reference?: UserVideoV2NutsonParentResourceReference | null;
    /**
     * Number of votes
     */
    votes?: number;
    /**
     * Whether the video can be voted on
     */
    is_votable?: boolean;
    /**
     * ID of the show
     */
    show_id?: string | null;
    /**
     * Whether the viewer has voted
     */
    is_voted?: boolean;
    /**
     * Current stage of the challenge
     */
    challenge_stage?: string;
    /**
     * Number of views in the show
     */
    show_views?: number;
    /**
     * Whether the video is a challenge winner
     */
    is_challenge_winner?: boolean;
}

