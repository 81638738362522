import { ChangeDetectionStrategy, Component, DestroyRef, Input, OnInit, Signal, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FeedItem, FeedItemEnum, MediaActionsMode } from '@core/models';
import { PlatformService } from '@core/services';
import { SeoService } from '@core/services/seo/seo.service';
import { MediaItemFacade } from '@modules/media/media-item/media-item.facade';
import { ModalsNames } from '@data/const';
import { MediaSwiperComponent } from '@shared/components/media-swiper/media-swiper.component';
import { LoaderComponent } from '@shared/components';
import { ResponsiveLayoutComponent } from '@shared/layouts/responsive-layout/responsive-layout.component';

@Component({
  selector: 'cheelee-media',
  standalone: true,
  templateUrl: './media-item.component.html',
  styleUrls: [ './media-item.component.scss' ],
  providers: [ MediaItemFacade ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MediaSwiperComponent,
    LoaderComponent,
    ResponsiveLayoutComponent,
  ],
})
export class MediaItemComponent implements OnInit {

  protected readonly ModalsNames = ModalsNames;

  // From route
  @Input() public mediaId!: string;

  public readonly mediaItem = this.facade.mediaItem;

  public readonly mediaIsLoading = this.facade.mediaIsLoading;

  public get mediaForSwiper(): Signal<FeedItem[]> {
    return signal([ {
      type: FeedItemEnum.UserVideoV1,
      object: this.mediaItem(),
    } ] as FeedItem[]);
  }

  public MediaActionsMode = MediaActionsMode;

  constructor(
    private facade: MediaItemFacade,
    private platformService: PlatformService,
    private seoService: SeoService,
    private destroyRef: DestroyRef,
  ) {
  }

  public ngOnInit(): void {
    if (this.platformService.isServer()) {
      this.facade
        .getSnippet(this.mediaId)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((snippet) => this.seoService.setSeoTags(snippet));
    }

    this.facade.loadMediaItem(this.mediaId);
    this.destroyRef.onDestroy(() => {
      this.facade.clearMediaItem();
    });
  }
}
