import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';

import { HeaderComponent, IconComponent } from '@shared/components';
import { I18NextModule } from 'angular-i18next';
import { OpenGetAppDirective } from '@shared/directives';
import { TopButtonComponent } from '@shared/components/media-swiper/components/top-button/top-button.component';
import { AnalyticsEventsEnum } from '@core/models/analytics.model';

@Component({
  standalone: true,
  selector: 'cheelee-feed-nav',
  templateUrl: './feed-nav.component.html',
  styleUrls: ['./feed-nav.component.scss'],
  imports: [
    HeaderComponent,
    I18NextModule,
    IconComponent,
    OpenGetAppDirective,
    TopButtonComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedNavComponent {

  public toggleMuteClicked = output();

  public isMuted = input<boolean>();

  protected readonly analyticsEvents = AnalyticsEventsEnum;

}
