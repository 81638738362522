/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserVideoV2AuthorAvatar } from './userVideoV2AuthorAvatar';


export interface UserVideoV2Author { 
    /**
     * ID of the author
     */
    id?: string;
    /**
     * Username of the author (without @)
     */
    username?: string;
    avatar?: UserVideoV2AuthorAvatar;
    /**
     * Whether the author is verified
     */
    is_verified?: boolean;
    /**
     * Whether the viewer follows the author
     */
    is_following?: boolean;
    /**
     * Whether the author can be followed
     */
    is_followable?: boolean;
    /**
     * Whether the author can be blocked
     */
    is_blockable?: boolean;
}

