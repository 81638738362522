import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { map, of, switchMap } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import { ErrorResponse } from '@core/models';
import { MediaRepository } from '@core/repositories/media.repository';
import { MAX_MEDIA_VIEW_COUNT, NavigateMediaDirections } from '@data/const';
import { mediaSelectors } from '@store/media/media.selectors';
import { routerActions, routerSelectors } from '@store/router';

import { mediaActions } from './media.actions';

@Injectable()
export class MediaEffects {

  constructor(
    private actions: Actions,
    private mediaRepository: MediaRepository,
    private store: Store,
  ) {
  }

  public loadMedia$ = createEffect(() => {
    return this.actions.pipe(
      ofType(mediaActions.loadMedia),
      switchMap(({ media_id }) =>
        this.mediaRepository.getMediaById(media_id).pipe(
          map(({ data: media }) => mediaActions.loadMediaSuccess({ media })),
          catchError((error: unknown) => of(mediaActions.loadMediaFailure({ error: error as ErrorResponse }))),
        ),
      ),
    );
  },
  );

  public loadFeedMediaInit$ = createEffect(() => {
    return this.actions.pipe(
      ofType(mediaActions.loadFeedMedia),
      concatLatestFrom(() => [
        this.store.select(mediaSelectors.selectFeedNextPageToken),
      ]),
      map(([ { isNextPart, isNavigateNext }, page_token ]) =>
        page_token === null
          ? mediaActions.loadFeedMediaComplete()
          : mediaActions.loadFeedMediaInit({ page_token, isNextPart, isNavigateNext }),
      ),
    );
  },
  );

  public loadFeedMedia$ = createEffect(() => {
    return this.actions.pipe(
      ofType(mediaActions.loadFeedMediaInit),
      concatLatestFrom(() => [
        this.store.select(routerSelectors.selectQueryRouterParam('country_iso')),
      ]),
      switchMap(([{ isNextPart, isNavigateNext, page_token }, country_iso]) =>
        this.mediaRepository.getFeed(page_token ?? undefined, country_iso).pipe(
          map((page) =>
            mediaActions.loadFeedMediaSuccess({ page, isNextPart, isNavigateNext }),
          ),
          catchError((error: unknown) => of(mediaActions.loadFeedMediaFailure({ error: error as ErrorResponse }))),
        ),
      ),
    );
  },
  );

  public setCurrentMediaIndex$ = createEffect(() => {
    return this.actions.pipe(
      ofType(mediaActions.navigateMediaSuccess),
      map(({ select_id }) => mediaActions.setCurrentMediaIndex({ select_id })),
    );
  });

  public openMedia = createEffect(() => {
    return this.actions.pipe(
      ofType(mediaActions.openUserMedia),
      map(({ media_id }) => routerActions.navigate({ path: `/media/${ media_id }` })),
    );
  });

  public getSnippet$ = createEffect(() => {
    return this.actions.pipe(
      ofType(mediaActions.getSnippet),
      switchMap(({ media_id }) =>
        this.mediaRepository.getMediaSnippet(media_id).pipe(
          map((snippet) => mediaActions.getSnippetSuccess({ snippet: snippet.data })),
          catchError((error: unknown) => of(mediaActions.getSnippetFailure({ error: error as ErrorResponse }))),
        ),
      ),
    );
  },
  );

  // public navigateNextMediaInit$ = createEffect(() => {
  //   return this.actions.pipe(
  //     ofType(mediaActions.navigateMedia),
  //     filter(({ direction }) => direction === NavigateMediaDirections.FORWARD),
  //     concatLatestFrom(() => [
  //       this.store.select(mediaSelectors.selectNextMedia),
  //       this.store.select(mediaSelectors.selectFeedNextPageToken),
  //       this.store.select(mediaSelectors.selectCurrentMediaIndex),
  //     ]),
  //     map(([ {}, nextMedia, nextPageToken, currentIndex ]) => {
  //       if (!nextMedia || isNil(currentIndex)) {
  //         if (!nextPageToken) {
  //           return mediaActions.navigateMediaError();
  //         }
  //
  //         return mediaActions.loadFeedMedia({ isNextPart: true, isNavigateNext: true });
  //       }
  //
  //       return mediaActions.navigateMediaSuccess({ media_id: nextMedia.media_id, index: currentIndex + 1 });
  //     }),
  //   );
  // },
  // );
  //
  // public navigatePrevMediaInit$ = createEffect(() => {
  //   return this.actions.pipe(
  //     ofType(mediaActions.navigateMedia),
  //     filter(({ direction }) => direction === NavigateMediaDirections.BACKWARD),
  //     concatLatestFrom(() => [
  //       this.store.select(mediaSelectors.selectPrevMedia),
  //       this.store.select(mediaSelectors.selectCurrentMediaIndex),
  //     ]),
  //     map(([ {}, prevMedia, currentIndex ]) => {
  //       if (!prevMedia || isNil(currentIndex)) {
  //         return mediaActions.navigateMediaError();
  //       }
  //
  //       return mediaActions.navigateMediaSuccess({ media_id: prevMedia.media_id, index: currentIndex - 1 });
  //     }),
  //   );
  // },
  // );

  public navigateOnLoadMediaFeed$ = createEffect(() => {
    return this.actions.pipe(
      ofType(mediaActions.loadFeedMediaSuccess),
      filter(({ isNavigateNext }) => !!isNavigateNext),
      map(() => mediaActions.navigateMedia({ direction: NavigateMediaDirections.FORWARD })),
    );
  },
  );

  public reloadFeedMedia$ = createEffect(() => {
    return this.actions.pipe(
      ofType(mediaActions.clearFeed),
      filter(({ needToReloadFeedMedia }) => needToReloadFeedMedia),
      map(() => mediaActions.loadFeedMedia({})),
    );
  },
  );

  public shareMedia$ = createEffect(() => {
    return this.actions.pipe(
      ofType(mediaActions.shareMedia),
      concatLatestFrom(() => [
        this.store.select(mediaSelectors.selectFeed),
        this.store.select(mediaSelectors.selectMedia),
      ]),
      switchMap(([ { media_id }, mediaFeed, mediaItem ]) =>
        this.mediaRepository.shareAsync(media_id).pipe(
          map(() => mediaActions.shareMediaSuccess({ media_id, isSingle: !mediaFeed.length && !!mediaItem })),
          catchError((error: unknown) => of(mediaActions.shareMediaFailure({ error: error as ErrorResponse }))),
        ),
      ),
    );
  });

  public addMediaViewData$ = createEffect(() => {
    return this.actions.pipe(
      ofType(mediaActions.addMediaViewData),
      concatLatestFrom(() => [
        this.store.select(mediaSelectors.selectMediaViewsCount),
        this.store.select(routerSelectors.selectFeedType),
      ]),
      map(([ { data, force }, count, view_source ]) => {
        const fulfilledData = { ...data, view_source };
        return count >= MAX_MEDIA_VIEW_COUNT || force
          ? mediaActions.pushMediaViews({ data: fulfilledData })
          : mediaActions.updateMediaViewStorage({ data: fulfilledData });
      }),
    );
  });

  public pushMediaViews$ = createEffect(() => {
    return this.actions.pipe(
      ofType(mediaActions.pushMediaViews),
      concatLatestFrom(() => this.store.select(mediaSelectors.selectMediaViews)),
      switchMap(([ { data }, mediaViews ]) =>
        this.mediaRepository.postMediaView({ media: mediaViews }).pipe(
          map(() => mediaActions.pushMediaViewsSuccess({ data })),
          catchError((error: unknown) =>
            of(mediaActions.pushMediaViewsFailure({ data, error: error as ErrorResponse })),
          ),
        ),
      ),
    );
  });

  public pushMediaOnClear$ = createEffect(() => {
    return this.actions.pipe(
      ofType(mediaActions.clearFeed),
      concatLatestFrom(() => this.store.select(mediaSelectors.selectMediaViewsCount)),
      filter(([ , count ]) => !!count),
      map(() => mediaActions.pushMediaViews({})),
    );
  });
}
