import { FeedItem, isFeedItemOfMediaType, ViewSource, ViewSourceType } from '@core/models/feed.model';
import { Injectable } from '@angular/core';

export interface MediaViewModel {
  media_id: string;
  duration: number;
  view_source: ViewSourceType;
  recommendation_id: string;
  opened_at: number;
  showed_at: number;
}

export class MediaView implements MediaViewModel {
  private initialized = false;

  private startTime = 0;

  public media_id = '';

  public duration = 0;

  public view_source = ViewSource.Unknown;

  public recommendation_id = '';

  public opened_at = 0;

  public showed_at = 0;

  get isInitialized(): boolean {
    return this.initialized;
  }

  public init() {
    this.initialized = true;
  }

  get data(): MediaViewModel {
    return {
      media_id: this.media_id,
      duration: this.duration,
      view_source: this.view_source,
      recommendation_id: this.recommendation_id,
      opened_at: this.opened_at,
      showed_at: this.showed_at,
    };
  }

  constructor(data?: Partial<MediaView>) {
    if (data) {
      Object.assign(this, data);
    }
  }

  public update(data?: Partial<MediaView>): void {
    if (data) {
      Object.assign(this, {
        ...data,
      });
    }
  }

  public playVideo(): void {
    this.startTime = new Date().getTime();
  }

  public get isPlaying() {
    return !!this.startTime;
  }

  public pauseVideo(): void {
    this.duration = this.duration + (new Date().getTime() - this.startTime) / 1000;
    this.startTime = 0;
  }

  public updateDuration(): void {
    if (this.isPlaying) {
      this.duration = this.duration + (new Date().getTime() - this.startTime) / 1000;
    }
  }
}

@Injectable()
export class MediaViewService {
  private mediaView: MediaView = new MediaView();

  public init(feedItem?: FeedItem): void {
    if (!feedItem || !isFeedItemOfMediaType(feedItem)) {
      return;
    }
    const media = feedItem.object;
    this.mediaView = new MediaView();
    this.mediaView.update({
      media_id: media.media_id,
      recommendation_id: media.recommendation_id ?? undefined,
      opened_at: new Date().getTime(),
    });
    this.mediaView.init();
  }

  public update(data?: Partial<MediaView>): void {
    this.mediaView.update(data);
  }

  public sendData(callBack: (data: MediaViewModel) => void): void {
    if (this.mediaView.isInitialized) {
      this.mediaView.updateDuration();
      callBack(this.mediaView.data);
      this.mediaView = new MediaView();
    }
  }

  public pauseVideo(): void {
    this.mediaView.pauseVideo();
  }

  public playVideo(): void {
    this.mediaView.playVideo();
  }
}
