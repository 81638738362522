import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  input,
  OnDestroy,
  OnInit,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { I18NextModule } from 'angular-i18next';
import { RippleDirective } from '@shared/directives';
import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { AnalyticsEventsEnum } from '@core/models/analytics.model';
import { AnalyticsDirective } from '@shared/directives/analytics.directive';

@Component({
  selector: 'cheelee-top-button',
  standalone: true,
  imports: [
    I18NextModule,
    RippleDirective,
    AnalyticsDirective,
  ],
  templateUrl: './top-button.component.html',
  styleUrl: './top-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('flyAndFade', [
      transition('* => fly', [
        animate(
          '1000ms',
          keyframes([
            style({ transform: 'translate(80px, 80px) scale(1)', opacity: 1, offset: 0.7 }),
            style({ transform: 'translate(0px, 0px) scale(1.5)', opacity: 0.9, offset: 1 }),
          ]),
        ),
      ]),
    ]),
  ],
})
export class TopButtonComponent implements OnInit, OnDestroy {

  public coin1 = signal('');

  public coin2 = signal('');

  public icon = input.required<string>();

  public eventName = input.required<AnalyticsEventsEnum>();

  public earn = input(false, { transform: booleanAttribute });

  private button = viewChild<ElementRef<HTMLButtonElement>>('buttonRef');

  public clicked = output();

  private timeoutId: any;

  rotateButton() {
    const button = this.button()?.nativeElement;
    if (!button) {
      return;
    }

    this.coin1.set('fly');
    setTimeout(() => {
      this.coin2.set('fly');
    }, 500);
    setTimeout(() => {
      this.coin1.set('');
    }, 1000);
    setTimeout(() => {
      this.coin2.set('');
      button.classList.add('rotate-once');
    }, 1500);
    setTimeout(() => {
      button.classList.remove('rotate-once');
    }, 2000);
  }

  ngOnInit() {
    if (this.earn()) {
      this.scheduleNextAction();
    }
  }

  performAction() {
    this.rotateButton();
  }


  private scheduleNextAction() {
    const randomDelay = this.getRandomDelay(3000, 8000);
    this.timeoutId = setTimeout(() => {
      this.performAction();
      this.scheduleNextAction();
    }, randomDelay);
  }

  private getRandomDelay(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  ngOnDestroy() {
    clearTimeout(this.timeoutId);
  }

  protected readonly analyticsEvents = AnalyticsEventsEnum;
}
