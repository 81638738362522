import { createReducer, on } from '@ngrx/store';

import { getABTestingGroup, getToken } from '@core/helpers/cookie.helper';
import { AbTestingGroup } from '@data/config';

import { sessionActions } from './session.actions';

export const sessionFeatureKey = 'session';

export interface SessionState {
  user_id?: string;
  access_token?: string;
  refresh_token?: string;
  abTestingGroup?: AbTestingGroup;
}

export const initialState: SessionState = {
  user_id: getToken()?.user_id,
  access_token: getToken()?.access_token,
  refresh_token: getToken()?.refresh_token,
  abTestingGroup: getABTestingGroup(),
};

export const sessionReducer = createReducer(
  initialState,
  on(
    sessionActions.getAuthTokenSuccess,
    (state, { token }): SessionState => ({
      ...state,
      refresh_token: token.refresh_token,
      access_token: token.access_token,
      user_id: !token.required_2fa ? token.user_id : undefined,
    }),
  ),
  on(
    sessionActions.removeAuthSessionSuccess,
    (state): SessionState => ({
      ...state,
      user_id: undefined,
      access_token: undefined,
      refresh_token: undefined,
    }),
  ),
);
