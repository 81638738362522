export function generateHash(data: any): string {
  const json = JSON.stringify(data);
  let hash = 0, i, chr;
  for (i = 0; i < json.length; i++) {
    chr = json.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash.toString();
}
