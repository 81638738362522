import { createAction, props } from '@ngrx/store';

import { Media, Snippet } from '@api/backend';
import { ErrorResponse, FeedPage } from '@core/models';
import { NavigateMediaDirections } from '@data/const';
import { MediaViewModel } from '@core/services';

export const mediaActions = {
  /**
   *
   * Load Media
   */
  loadMedia: createAction(
    '[Media] Load Media',
    props<{ media_id: string }>(),
  ),

  loadMediaSuccess: createAction(
    '[Media] Load Media Success',
    props<{ media: Media }>(),
  ),

  loadMediaFailure: createAction(
    '[Media] Load Media Failure',
    props<{ error: ErrorResponse }>(),
  ),

  clearMedia: createAction(
    '[Media] Clear Media',
  ),
  /**
   * Load Feed Media
   */
  loadFeedMedia: createAction(
    '[Media] Load Feed Media',
    props<{
      isNextPart?: boolean;
      isNavigateNext?: boolean;
    }>(),
  ),
  loadFeedMediaInit: createAction(
    '[Media] Load Feed Media Init',
    props<{
      // feed_type: ViewSourceType;
      page_token: string | null | undefined;
      isNextPart?: boolean;
      isNavigateNext?: boolean;
    }>(),
  ),
  loadFeedMediaSuccess: createAction(
    '[Media] Load Feed Media Success',
    props<{
      page: FeedPage;
      isNextPart?: boolean;
      isNavigateNext?: boolean;
    }>(),
  ),
  loadFeedMediaFailure: createAction(
    '[Media] Load Feed Media Failure',
    props<{ error: ErrorResponse }>()),
  loadFeedMediaComplete: createAction(
    '[Media] Load Feed Media Complete',
  ),
  clearFeed: createAction(
    '[Media] Clear Feed',
    (needToReloadFeedMedia: boolean = false) => ({ needToReloadFeedMedia }),
  ),

  /**
   * Play Pause
   */
  playMedia: createAction('[Media] Play'),
  pauseMedia: createAction('[Media] Pause'),

  /**
   * Mute
   */
  muteMedia: createAction('[Media] Mute', props<{ isMuted: boolean }>()),

  /**
   * Open media
   */
  openUserMedia: createAction('[Media] Open user media', props<{ media_id: string }>()),
  closeMedia: createAction('[Media] Close media'),

  /**
   * Navigate
   */
  navigateMedia: createAction('[Media] Navigate Media', props<{ direction: NavigateMediaDirections }>()),
  navigateMediaSuccess: createAction('[Media] Navigate Media Success', props<{ select_id: string; index: number }>()),
  navigateMediaError: createAction('[Media] Navigate Media Error'),

  /**
   * Set Current Media Index
   */
  setCurrentMediaIndex: createAction('[Media] Set Current Media Index', props<{ select_id: string | undefined }>()),
  resetCurrentMediaIndex: createAction('[Media] Reset Current Media Index'),

  /**
   * Spippet
   */
  getSnippet: createAction('[Media] Get Snippet', props<{ media_id: string }>()),
  getSnippetSuccess: createAction('[Media] Get Snippet Success', props<{ snippet: Snippet }>()),
  getSnippetFailure: createAction('[Media] Get Snippet Failure', props<{ error: ErrorResponse }>()),

  /**
   * Share Media
   */
  shareMedia: createAction('[Media] Share', props<{ media_id: string }>()),
  shareMediaSuccess: createAction('[Media] Share Success', props<{ media_id: string; isSingle: boolean }>()),
  shareMediaFailure: createAction('[Media] Share Failure', props<{ error: ErrorResponse }>()),

  /**
   * MediaView
   */
  addMediaViewData: createAction(
    '[Media] Add Media View Data',
    props<{ data: MediaViewModel, force?: boolean }>(),
  ),
  updateMediaViewStorage: createAction(
    '[Media] Update Media View Storage',
    props<{ data: MediaViewModel }>(),
  ),
  pushMediaViewsInit: createAction(
    '[Media] Push Media Views Init',
    props<{ data: MediaViewModel }>(),
  ),
  pushMediaViews: createAction(
    '[Media] Push Media Views',
    props<{ data?: MediaViewModel }>(),
  ),
  pushMediaViewsSuccess: createAction(
    '[Media] Push Media Views Success',
    props<{ data?: MediaViewModel }>(),
  ),
  pushMediaViewsFailure: createAction(
    '[Media] Push Media Views Failure',
    props<{ data?: MediaViewModel; error: ErrorResponse }>(),
  ),
};
