import { inject, Injectable } from '@angular/core';
import { Analytics, logEvent } from '@angular/fire/analytics';
import { YandexMetrikaService } from '@core/services';
import { Store } from '@ngrx/store';
import { sessionSelectors } from '@store/session';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {

  private analytics = inject(Analytics);

  private abPrefix = inject(Store).selectSignal(sessionSelectors.selectABGroupPostfixWithVersion);

  private yandexMetrikaService = inject(YandexMetrikaService);

  public logEvent(eventName: string, params?: { [key:string]: string | undefined }): void {
    try {
      this.yandexMetrikaService.reachGoal(`${eventName}${this.abPrefix()}`);
      logEvent(this.analytics, `${eventName}`, {
        ...params,
      });
    } catch (e) {}
  }
}
