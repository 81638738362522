@let mediaItem = media();
@let userVideoItem = userVideo();
@let adItem = ad();

@if (mediaItem) {
  <ng-container
    *ngTemplateOutlet="mediaAboutTpl; context: {
      userName: mediaItem.author?.user_name,
      isConfirmed: mediaItem.author?.is_identity_confirmed,
      description: mediaItem.media_description,
      views: mediaItem.counters.views,
      audioTrackName: getAudioTrackName(mediaItem.audio?.artist, mediaItem.audio?.title),
    }"
  ></ng-container>
} @else if (userVideoItem) {
  <ng-container
    *ngTemplateOutlet="mediaAboutTpl; context: {
      userName: userVideoItem.author?.username,
      isConfirmed: userVideoItem.author?.is_verified,
      description: userVideoItem.meta?.description?.text,
      views: userVideoItem.views?.count,
      audioTrackName: getAudioTrackName(userVideoItem.audio?.artist?.display_name, userVideoItem.audio?.title),
    }"
  ></ng-container>
} @else if (adItem) {
  <ng-container
    *ngTemplateOutlet="adAboutTpl; context: {
      userName: adItem.profile?.object?.display_name,
      ageRestriction: adItem.age_restriction,
      description: adItem.text,
      adButton: adItem.button,
    }"
  ></ng-container>
}

<ng-template
  #mediaAboutTpl
  let-userName="userName"
  let-isConfirmed="isConfirmed"
  let-description="description"
  let-views="views"
  let-audioTrackName="audioTrackName"
>
  <div class="about">
    <div class="about__views">
      <cheelee-icon
        class="about__views-icon"
        iconId="play-video-icon"
        size="16"
      />
      <span class="about__views-count">
        {{ views | shortNumber }}
      </span>
    </div>

    <div class="about__username">
      <a
        class="about__username-text text-1 bold"
        (click)="goToUserPage()"
      >
        <span> &#64;{{ userName }} </span>
      </a>
      <cheelee-icon
        *ngIf="isConfirmed"
        class="about__confirmed-icon"
        iconId="confirmed-user-icon"
        size="14"
      />
    </div>

    @if (description) {
      <cheelee-show-more-text
        class="about__row"
        [text]="description"
      />
    }

    @if (audioTrackName) {
      <div
        class="about__audio about__row"
      >
        <img
          class="about__music-img"
          alt="media audio"
          cheeleeRepeatAnimation
          width="20"
          height="20"
          ngSrc="./assets/img/music.png"
          repeatAnimationAfter="8000"
        />
        <div class="about__audio-name-wrapper">
        <span
          #audioName
          class="about__audio-name"
          [class.running-text]="canPlayRunningText()"
          cheeleeRepeatAnimation
          needPlayAnimationImmediately
          repeatAnimationAfter="2000"
        >
          {{ audioTrackName }}
          {{ canPlayRunningText() ? '&nbsp;&nbsp;' + audioTrackName : '' }}
        </span>
        </div>
        <span *ngIf="canPlayRunningText()">&nbsp;...</span>
      </div>
    }
  </div>
</ng-template>

<ng-template
  #adAboutTpl
  let-userName="userName"
  let-ageRestriction="ageRestriction"
  let-description="description"
  let-adButton="adButton"
>
  @if (!adButton || isAdVisible()) {
    <div class="about">
      <div class="about__username">
        <a
          class="about__username-text text-1 bold"
          (click)="goToUserPage()"
        >
          <span> &#64;{{ userName }}</span>
        </a>
      </div>

      <ul class="about__tags">
        <li class="about__tag">
          {{ 'common:sponsored' | i18nextEager }}
        </li>
        @if (ageRestriction) {
          <li class="about__tag">
            {{ ageRestriction }}
          </li>
        }
      </ul>

      @if (description) {
        <cheelee-show-more-text
          class="about__row"
          [text]="description"
        />
      }

      @if (adButton) {
        <div class="about__action-button">
          <cheelee-button
            withFullWidth
            isSmall
            [customBackgroundColor]="adButton.color ?? '#01FFC2'"
            [customColor]="isDarkColor(adButton.color ?? '#01FFC2') ? '#FFFFFF' : '#140132'"
            (clicked)="onButtonClick(adButton)"
          >
            {{ adButton.text }}
          </cheelee-button>
        </div>
      }
    </div>
  }
</ng-template>
