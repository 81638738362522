import { Directive, HostListener, Input } from '@angular/core';
import { AnalyticsService } from '@core/services/analytics/analytics.service';
import { AnalyticsEventsEnum } from '@core/models/analytics.model';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[analytics]',
  standalone: true,
})
export class AnalyticsDirective {

  @Input('analytics') target!: AnalyticsEventsEnum; // Название цели в Метрике

  @Input() params: any = {}; // Дополнительные параметры для Метрики

  constructor(
    private analyticsService: AnalyticsService,
  ) {}

  @HostListener('click', ['$event'])
  handleClick(): void {
    this.analyticsService.logEvent(this.target, this.params);
  }

}
