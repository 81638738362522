/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserVideoV2AudioCover } from './userVideoV2AudioCover';
import { UserVideoV2AudioArtist } from './userVideoV2AudioArtist';


export interface UserVideoV2Audio { 
    /**
     * ID of the audio track
     */
    id?: string;
    artist?: UserVideoV2AudioArtist;
    /**
     * Title of the audio track
     */
    title?: string;
    cover?: UserVideoV2AudioCover;
}

