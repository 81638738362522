/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserVideoV2VideoBitratesInner { 
    /**
     * URL to the video with specified bitrate
     */
    url?: string;
    /**
     * Bitrate in bits per second
     */
    bitrate?: number;
    /**
     * Codec name (e.g., h265, h264)
     */
    codec?: string;
}

