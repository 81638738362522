<cheelee-responsive-layout tabbar>
  <ng-container>
    <div class="feed">
      @if (isMobile() && feed().length > 0) {
        <cheelee-media-swiper [feed]="feed()"/>
      }
      @if (feedIsLoading() && !feed().length) {
        <cheelee-loader class="feed__loader"/>
      }
    </div>
  </ng-container>
  <ng-container ngProjectAs="left">
    menu
  </ng-container>
  <ng-container ngProjectAs="right">
<!--    <cheelee-user [browsingUser]="user()"/>-->
  </ng-container>
</cheelee-responsive-layout>

