<cheelee-responsive-layout>
  <ng-container>
<div class="media">

  @if (mediaItem()) {
    <cheelee-media-swiper
      [feed]="mediaForSwiper()"
      [mode]="MediaActionsMode.SINGLE"
    />
  }
  @if (mediaIsLoading() && !mediaItem()) {
    <cheelee-loader class="media__loader"/>
  }
</div>
  </ng-container>
</cheelee-responsive-layout>
