import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  OnInit,
  Signal,
  signal,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FeedFacade } from '@core/facades/feed.facade';
import { PlatformService } from '@core/services';
import { MediaSwiperComponent } from '@shared/components/media-swiper/media-swiper.component';
import { ResponsiveLayoutComponent } from '@shared/layouts/responsive-layout/responsive-layout.component';
import { LoaderComponent } from '@shared/components';
import { FeedItem } from '@core/models';
import { BreakpointFacade } from '@core/facades';

@Component({
  selector: 'cheelee-feed',
  standalone: true,
  templateUrl: './feed.component.html',
  styleUrls: [ './feed.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ResponsiveLayoutComponent,
    MediaSwiperComponent,
    LoaderComponent,

  ],
})
export class FeedComponent implements OnInit {

  public isMobile = inject(BreakpointFacade).isMobile;

  public readonly feed = signal<FeedItem[]>([]);

  public readonly feed$ = this.feedFacade.feed$;

  public readonly feedIsLoading = this.feedFacade.feedIsLoading;

  private swiper: Signal<MediaSwiperComponent | undefined> = viewChild(MediaSwiperComponent);

  // public user = computed(() => {
  //   // return this.feed()[this.swiper()?.currentIndex() ?? 0]?.object?.author;
  //   return (this.feed()[this.swiper()?.currentIndex() ?? 0]?.object as Media)?.author;
  // });

  constructor(
    private feedFacade: FeedFacade,
    private platformService: PlatformService,
    private destroyRef: DestroyRef,
  ) {
  }

  public ngOnInit(): void {
    this.feedFacade.playMedia();
    this.feed$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((feed) => {
        if (feed?.length) {
          this.feed.set(feed);
        }
      });

    if (this.platformService.isBrowser()) {
      this.feedFacade.loadFeedMedia();
    }

    this.destroyRef.onDestroy(() => {
      this.feedFacade.clear();
    });
  }

  public loadNextFeed(): void {
    this.feedFacade.getNextFeedMedia();
  }

}
