import { createFeatureSelector, createSelector } from '@ngrx/store';

import { SessionToken } from '@core/models';

import { sessionFeatureKey, SessionState } from './session.reducer';
import { TEST_VERSION } from '@data/config';

const selectSessionFeature = createFeatureSelector<SessionState>(sessionFeatureKey);

const selectSessionToken = createSelector(
  selectSessionFeature,
  (state) =>
    ({
      user_id: state.user_id,
      access_token: state.access_token,
      refresh_token: state.refresh_token,
    }) as SessionToken,
);

const selectSessionUserId = createSelector(selectSessionFeature, (state) => state.user_id);

const selectIsSetUser = createSelector(selectSessionFeature, (state) => !!state.user_id);

const selectABGroupPostfix = createSelector(selectSessionFeature, (state) => {
  return state.abTestingGroup ? `test-${state.abTestingGroup}` : '';
});

const selectABGroupPostfixWithVersion = createSelector(selectSessionFeature, (state) => {
  return state.abTestingGroup ? `_${TEST_VERSION}_test-${state.abTestingGroup}` : '';
});

export const sessionSelectors = {
  selectSessionToken,
  selectSessionUserId,
  selectIsSetUser,
  selectABGroupPostfix,
  selectABGroupPostfixWithVersion,
};
