/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserVideoV2MetaDescriptionTokensInner } from './userVideoV2MetaDescriptionTokensInner';


export interface UserVideoV2MetaDescription { 
    /**
     * Original text
     */
    text?: string;
    tokens?: Array<UserVideoV2MetaDescriptionTokensInner>;
}

