import { createReducer, on } from '@ngrx/store';

import { Media, Snippet } from '@api/backend';

import { mediaActions } from './media.actions';
import {
  feedAdapter,
  FeedState,
  initialFeedState,
  initialMediaViewState,
  mediaViewsAdapter,
  MediaViewState,
} from '@store/media/entity-adapters';
import { FeedItemEnum, FeedItemOfMediaType } from '@core/models';

export const mediaFeatureKey = 'media';

export interface MediaState {
  feed: FeedState;
  mediaViews: MediaViewState;
  media?: Media;
  mediaIsLoading: boolean;
  snippet?: Snippet;
  isPlaying: boolean;
  isMuted: boolean;
  isModalView: boolean;
  currentMediaIndex: number;
}

export const initialState: MediaState = {
  feed: initialFeedState,
  mediaViews: initialMediaViewState,
  currentMediaIndex: 0,
  mediaIsLoading: false,
  isPlaying: true,
  isMuted: true,
  isModalView: false,
};

export const mediaReducer = createReducer(
  initialState,
  on(
    mediaActions.loadMedia,
    (state): MediaState => ({
      ...state,
      mediaIsLoading: true,
    }),
  ),
  on(
    mediaActions.loadMediaSuccess,
    (state, { media }): MediaState => ({
      ...state,
      media,
      mediaIsLoading: false,
    }),
  ),
  on(
    mediaActions.loadMediaFailure,
    (state): MediaState => ({
      ...state,
      mediaIsLoading: false,
    }),
  ),
  on(mediaActions.clearMedia, (state): MediaState => ({ ...state, media: undefined })),
  on(
    mediaActions.loadFeedMedia,
    (state, { isNextPart }): MediaState => ({
      ...state,
      feed: {
        ...state.feed,
        nextPageToken: isNextPart ? state.feed.nextPageToken : undefined,
      },
    }),
  ),
  on(
    mediaActions.loadFeedMediaInit,
    (state): MediaState => ({
      ...state,
      feed: {
        ...state.feed,
        isLoading: true,
      },
    }),
  ),
  on(
    mediaActions.loadFeedMediaSuccess,
    (state, { page, isNextPart }): MediaState => ({
      ...state,
      feed: isNextPart
        ? feedAdapter.addMany(page.items ?? [], {
          ...state.feed,
          nextPageToken: page.next_page_token as string,
          isLoading: false,
        })
        : feedAdapter.setAll(page.items ?? [], {
          ...state.feed,
          nextPageToken: page.next_page_token as string,
          isLoading: false,
        }),
    }),
  ),
  on(
    mediaActions.loadFeedMediaFailure,
    (state): MediaState => ({
      ...state,
      feed: {
        ...state.feed,
        isLoading: false,
      },
    }),
  ),
  on(
    mediaActions.loadFeedMediaComplete,
    (state): MediaState => ({
      ...state,
      feed: {
        ...state.feed,
        isLoading: false,
      },
    }),
  ),
  on(
    mediaActions.clearFeed,
    (state): MediaState => ({
      ...state,
      feed: feedAdapter.getInitialState({
        recommendation_id: undefined,
        nextPageToken: undefined,
        isLoading: false,
      }),
    }),
  ),
  on(
    mediaActions.playMedia,
    (state): MediaState => ({
      ...state,
      isPlaying: true,
    }),
  ),
  on(
    mediaActions.pauseMedia,
    (state): MediaState => ({
      ...state,
      isPlaying: false,
    }),
  ),
  on(
    mediaActions.muteMedia,
    (state, { isMuted }): MediaState => ({
      ...state,
      isMuted,
    }),
  ),
  on(
    mediaActions.closeMedia,
    (state): MediaState => ({
      ...state,
      currentMediaIndex: -1,
    }),
  ),
  on(
    mediaActions.navigateMedia,
    (state): MediaState => ({
      ...state,
    }),
  ),
  on(
    mediaActions.setCurrentMediaIndex,
    (state, { select_id }): MediaState => ({
      ...state,
      currentMediaIndex: state.feed.ids.findIndex((id) => id === select_id) ?? 0,
    }),
  ),
  on(
    mediaActions.resetCurrentMediaIndex,
    (state): MediaState => ({ ...state, currentMediaIndex: 0 }),
  ),
  on(
    mediaActions.shareMediaSuccess,
    (state, { media_id, isSingle }): MediaState => {
      if (!isSingle) {
        const mediaSelectId = state.feed.ids.find(id => {
          const feedItem = state.feed.entities[id];
          if (feedItem && feedItem.type === FeedItemEnum.UserVideoV1) {
            const mediaId = feedItem.object.media_id;
            return mediaId === media_id;
          }
          return false;
        });
        if (!mediaSelectId) {
          return state;
        }
        const feedItemOfMediaType = state.feed.entities[mediaSelectId.toString()] as FeedItemOfMediaType;
        const media = feedItemOfMediaType.object as Media;
        const counters = media.counters;

        if (!mediaSelectId || !media || !counters) {
          return state;
        }

        return {
          ...state,
          feed: feedAdapter.updateOne(
            {
              id: mediaSelectId.toString(),
              changes: {
                ...feedItemOfMediaType,
                object: {
                  ...media,
                  counters: {
                    ...counters,
                    reposts: (counters?.reposts ?? 0) + 1,
                  },
                } as Media,
              },
            },
            state.feed,
          ),
        };
      }

      return {
        ...state,
        media: !state.media
          ? state.media
          : {
            ...state.media,
            counters: {
              ...state.media.counters,
              reposts: state.media.counters.reposts + 1,
            },
          },
      };
    }),
  on(
    mediaActions.updateMediaViewStorage,
    (state, { data }): MediaState => ({
      ...state,
      mediaViews: mediaViewsAdapter.addOne(data, state.mediaViews),
    }),
  ),
  on(
    mediaActions.pushMediaViewsSuccess,
    mediaActions.pushMediaViewsFailure,
    (state, { data }): MediaState => ({
      ...state,
      mediaViews: data ? mediaViewsAdapter.addOne(data, mediaViewsAdapter.getInitialState()) : state.mediaViews,
    }),
  ),
);
