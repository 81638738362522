/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserVideoV2MetaDescriptionTokensInnerData } from './userVideoV2MetaDescriptionTokensInnerData';
import { UserVideoV2MetaDescriptionTokensInnerPos } from './userVideoV2MetaDescriptionTokensInnerPos';


export interface UserVideoV2MetaDescriptionTokensInner { 
    pos?: UserVideoV2MetaDescriptionTokensInnerPos;
    /**
     * Text to display for the token
     */
    display_text?: string;
    data?: UserVideoV2MetaDescriptionTokensInnerData;
}

